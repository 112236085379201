<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button class="filter-item" type="primary" @click="handleCreate('')">
        新增
      </el-button>
      <!-- <el-select
        v-model="form.category_id"
        placeholder="请选择"
        class="filter-item"
      >
        <el-option
          v-for="item in category"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select> -->
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      border
      element-loading-text="Loading"
      fit
      highlight-current-row
    >
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="图片" min-width="100" align="center">
        <template slot-scope="scope">
          <el-image
            style="height: 80px"
            :preview-src-list="[scope.row.cover_url]"
            :src="scope.row.cover_url"
            class="banner-image"
            lazy
          />
        </template>
      </el-table-column>
      <!-- <el-table-column label="跳转类型" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag type="info" v-if="scope.row.link_type === 0">不跳转</el-tag>
          <el-tag type="success" v-if="scope.row.link_type === 1"
            >跳转网页
          </el-tag>
        </template>
      </el-table-column> -->
      <!-- <el-table-column label="跳转链接" min-width="200" align="center">
        <template slot-scope="scope">
          {{ scope.row.link_to }}
        </template>
      </el-table-column> -->
      <el-table-column label="排序" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.weight }}
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="200"
        align="center"
        class-name="small-padding fixed-width"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="handleCreate(scope.row.id)"
          >
            修改
          </el-button>

          <el-button size="mini" type="danger" @click="handleDel(scope.row.id)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <el-dialog
      :title="textMap[dialogStatus]"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form ref="form" :rules="rules" :model="form" label-width="160px">
        <el-form-item
          :label="`图片（670*300）`"
          prop="cover_url"
        >
          <upload-one
            width="50%"
            style="height: 230px"
            v-model="form.cover_url"
          ></upload-one>
        </el-form-item>
        <el-form-item label="排序" prop="weight">
          <el-input-number
            v-model="form.weight"
            :min="1"
            :max="9999"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="详情" prop="link_type">
         <Tinymce :height="200" v-model="form.description" :value="form.description"/>
        </el-form-item>
        <!-- <el-form-item label="链接" prop="link_to" v-if="form.link_type">
          <el-input type="text" v-model="form.link_to" clearable />
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading">
          确定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";
import UploadOne from "@/components/UploadOne/index.vue";
import Tinymce from '@/components/Tinymce'
export default {
  components: {
    UploadOne,
    Tinymce
  },
  data() {
    return {
      recommendSize: {
        height: 230,
        width: 500,
      },
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        category_id: 2,
      },
      dialogFormVisible: false,
      dialogStatus: "",
      textMap: {
        update: "修改",
        create: "新增",
      },
      category: [],
      form: {
        id: "",
        cover_url: "",
        weight: 1,
        link_type: 0,
        link_to: "",
        description: "",
        category_id: 2,
      },
      rules: {
        cover_url: [
          { required: true, message: "图片不能为空", trigger: "change" },
        ],
        weight: [
          { required: true, message: "权重不能为空", trigger: "change" },
        ],
        link_type: [
          { required: true, message: "跳转类型不能为空", trigger: "change" },
        ],
        // link_to: [{ required: true, message: '链接不能为空', trigger: 'change' }],
      },
      btnLoading: false,
    };
  },
  created() {
    // this.getCategories();
    this.getList();
  },
  watch: {
    //使用这个可以监听data中指定数据的变化,然后触发watch中对应的function的处理
    "form.category_id": function (newVal, oldVal) {
      this.getList();
    },
  },
  methods: {
    getList() {
      this.listLoading = true;
      // this.listQuery.category_id = this.form.category_id;
      request({
        url: "/api/schoolend/banners",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    getCategories() {
      this.listLoading = true;
      request({
        url: "/api/schoolend/banners/categories",
        method: "get",
        params: {},
      }).then((response) => {
        this.category = response.data;

        this.listLoading = false;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    resetForm() {
      this.form = {
        id: "",
        cover_url: "",
        weight: 1,
        link_type: 0,
        link_to: "",
        category_id: this.form.category_id,
      };
      this.btnLoading = false;
    },
    handleCreate(id) {
      this.$router.push("/match/bannerDetails?id=" + id);
    },
    saveData() {
      if (this.form.link_type && !this.form.link_to) {
        this.$message({
          type: "error",
          message: "跳转链接不能为空",
        });
        return;
      }
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          request({
            url: "/api/schoolend/banners/store",
            method: "post",
            data: this.form,
          })
            .then((res) => {
              this.btnLoading = false;
              this.dialogFormVisible = false;
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.getList();
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        }
      });
    },
    handleDel(id) {
      this.$confirm("确定要删除, 是否继续?", "提示", {
        type: "warning",
      }).then(() => {
        request({
          url: "/api/schoolend/banners/delete",
          method: "post",
          data: { id: id },
        }).then(() => {
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功",
          });
        });
      });
    },
  },
};
</script>

<style>
.el-image.banner-image img {
  width: auto;
}
</style>
